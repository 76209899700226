<template>
  <div class="p-recommendBottomSheet">
    <BottomSheet v-if="needTest" no-shadow>
      <template v-slot:header>
        <div class="p-recommendBottomSheet__header c-section">
          <div v-if="message" class="p-recommendBottomSheet__message">
            <div class="p-recommendBottomSheet__message__inner">
              味覚情報が登録されていません。<br />
              まずはあなたの味覚情報を登録してマップの準備をしましょう
            </div>
          </div>
          <Button
            style-type="primary"
            fluid
            class="p-recommendBottomSheet__testButton"
            @click="$emit('start-test')">
            あなたの味覚マップを設定する
          </Button>
        </div>
      </template>
    </BottomSheet>

    <BottomSheet
      ref="bottomSheet"
      v-else
      :show-close-icon="showCloseIcon"
      @close="$emit('close')">
      <template v-slot:header>
        <div class="p-recommendBottomSheet__header c-section">
          <div v-if="message" class="p-recommendBottomSheet__message">
            <div
              class="p-recommendBottomSheet__message__inner"
              v-html="message" />
          </div>
        </div>
      </template>

      <div class="c-section c-section--fluid c-section--mb0">
        <div
          class="c-section__inner c-section__inner--pt4 c-section__inner--pb0">
          <div class="c-cards c-cards--tiled">
            <div
              v-for="product in products"
              :key="product.id"
              class="c-cards__inner">
              <CoffeeCardTiled
                :href="ecUrl(product['ec_link_url'])"
                :title="product.name"
                :title-clamp="3"
                :src="product.image_url" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="paginatable" class="c-section">
        <div class="p-recommendBottomSheet__actions">
          <Button
            size="sm"
            style-type="tertiary"
            fluid
            @click="$emit('load-more')">
            コーヒーをもっと見る
          </Button>
        </div>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  props: {
    message: {
      type: String,
      default: null
    },

    products: {
      type: Array,
      required: true
    },

    showCloseIcon: {
      type: Boolean,
      default: false
    },

    needTest: {
      type: Boolean,
      default: false
    },

    paginatable: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const bottomSheet = ref(null);
    const ecUrl = (productUrl) => {
      return !productUrl
        ? false
        : context.root.$customUrlScheme.page('ec', `url=${productUrl}`);
    };

    const calcHeaderHeight = () => {
      bottomSheet.value?.calcHeaderHeight();
    };

    return {
      ecUrl,
      calcHeaderHeight,
      bottomSheet
    };
  }
});
</script>
