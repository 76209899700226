var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-recommendBottomSheet" },
    [
      _vm.needTest
        ? _c("BottomSheet", {
            attrs: { "no-shadow": "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-recommendBottomSheet__header c-section",
                        },
                        [
                          _vm.message
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "p-recommendBottomSheet__message",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "p-recommendBottomSheet__message__inner",
                                    },
                                    [
                                      _vm._v(" 味覚情報が登録されていません。"),
                                      _c("br"),
                                      _vm._v(
                                        " まずはあなたの味覚情報を登録してマップの準備をしましょう "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "Button",
                            {
                              staticClass: "p-recommendBottomSheet__testButton",
                              attrs: { "style-type": "primary", fluid: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("start-test")
                                },
                              },
                            },
                            [_vm._v(" あなたの味覚マップを設定する ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3563385809
            ),
          })
        : _c(
            "BottomSheet",
            {
              ref: "bottomSheet",
              attrs: { "show-close-icon": _vm.showCloseIcon },
              on: {
                close: function ($event) {
                  return _vm.$emit("close")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-recommendBottomSheet__header c-section",
                        },
                        [
                          _vm.message
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "p-recommendBottomSheet__message",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "p-recommendBottomSheet__message__inner",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.message),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "c-section c-section--fluid c-section--mb0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "c-section__inner c-section__inner--pt4 c-section__inner--pb0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-cards c-cards--tiled" },
                        _vm._l(_vm.products, function (product) {
                          return _c(
                            "div",
                            { key: product.id, staticClass: "c-cards__inner" },
                            [
                              _c("CoffeeCardTiled", {
                                attrs: {
                                  href: _vm.ecUrl(product["ec_link_url"]),
                                  title: product.name,
                                  "title-clamp": 3,
                                  src: product.image_url,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
              _vm.paginatable
                ? _c("div", { staticClass: "c-section" }, [
                    _c(
                      "div",
                      { staticClass: "p-recommendBottomSheet__actions" },
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              size: "sm",
                              "style-type": "tertiary",
                              fluid: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("load-more")
                              },
                            },
                          },
                          [_vm._v(" コーヒーをもっと見る ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }